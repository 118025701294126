import React, { Component } from "react";
import { Link } from "react-router-dom";
import QrReader from "react-qr-reader";
import Result from "../components/result";

class Scanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      delay: 2000,
      result: "No result",
      scanning: false
    };
    this.handleScan = this.handleScan.bind(this);
  }
  resetScanner = () => {
    this.setState({
      result: "No result"
    });
  };

  handleScan(data) {
    if (data) {
      console.log(data);
      this.setState({
        scanning: true,
        result: ""
      });
      fetch(
        "https://paladins-app.westhofen.me/wp-json/paladins/v1/check?gameday=" +
          this.props.match.params.id +
          "&ticket=" +
          data
      )
        .then(response => response.json())
        .then(response =>
          this.setState({
            result: response,
            scanning: false
          })
        );
    }
  }
  handleError(err) {
    console.error(err);
  }
  render() {
    const { result, scanning } = this.state;
    return (
      <div className={scanning ? "scanning" : ""}>
        <Link to="/" className="back" />
        <QrReader
          delay={this.state.delay}
          onError={this.handleError}
          onScan={this.handleScan}
          style={{ height: "100%", boxShadow: "none" }}
        />
        <Result
          scanning={scanning}
          resetScanner={this.resetScanner}
          gameid={this.props.match.params.id}
          data={result}
        />
      </div>
    );
  }
}
export default Scanner;
