import React, { Component } from "react";
import Success from "../img/success.svg";
import Scan from "../img/scan.svg";
import Error from "../img/error.svg";

class Result extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }
  checkOut = () => {
    fetch(
      "https://paladins-app.westhofen.me/wp-json/paladins/v1/checkout?gameday=" +
        this.props.gameid +
        "&ticket=" +
        this.props.data.id
    ).then(response => this.props.resetScanner());
  };

  render() {
    const { data, scanning } = this.props;
    if (scanning) {
      return (
        <div className="result">
          <div>
            <img className="m16" src={Scan} alt="success" />
            <br />
            Scannt ...
          </div>
        </div>
      );
    }
    if (data.error === "noticket") {
      return (
        <div className="result error">
          <div>
            <img className="m16" src={Error} alt="success" />
            <br />
            Kein Ticket gefunden.
          </div>
        </div>
      );
    }
    if (data.error === "checkedin") {
      return (
        <div className="result error">
          <div>
            <img className="m16" src={Error} alt="error" />
            <br />
            <div style={{ fontSize: "18px" }}>#{data.id}</div>
            Ticket bereits eingecheckt. <br />
            <br />
            Vollzahler: {data.vollzahler}
            <br />
            Ermäßigt: {data.reduced}
            <br />
            <br />
            <div
              className="btn btn-white-border"
              onClick={() => this.checkOut()}
            >
              Auschecken
            </div>
          </div>
        </div>
      );
    }
    if (data.id) {
      return (
        <div className="result success">
          <div>
            <img className="m16" src={Success} alt="success" />
            <br />
            <div style={{ fontSize: "18px" }}>#{data.id}</div>
            <br />
            Vollzahler: {data.vollzahler}
            <br />
            Ermäßigt: {data.reduced}
          </div>
        </div>
      );
    }
    return (
      <div className="result">
        <div>
          <img className="m16" src={Scan} alt="success" />
          <br />
          Ticket scannnen
        </div>
      </div>
    );
  }
}

export default Result;
