import React, { Component } from "react";

import Scanner from "./components/scanner";

import { HashRouter as Router, Route } from "react-router-dom";
import Gamelist from "./components/gamelist";
import "./App.css";

class App extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      data: ""
    };
  }
  componentDidMount() {
    fetch("https://paladins-app.westhofen.me/wp-json/paladins/v1/data")
      .then(response => response.json())
      .then(response =>
        this.setState({
          data: response,
          loading: false
        })
      );
  }
  render() {
    const { loading, data } = this.state;
    if (loading) {
      return "Lädt";
    }
    return (
      <Router>
        <div className="App">
          <Route
            exact
            path="/"
            render={() => <Gamelist data={data.schedule.open} />}
          />
          <Route path="/scan/:id" render={props => <Scanner {...props} />} />
        </div>
      </Router>
    );
  }
}

export default App;
