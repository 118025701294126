import React, { Component } from "react";
import { Link } from "react-router-dom";

class Gamelist extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { data } = this.props;
    console.log(data);
    return (
      <div className="card m16">
        <div className="row p16" style={{ fontWeight: "bold" }}>
          Spiele
        </div>
        {data.map((game, idx) => {
          if (game.team1.shortname === "Paladins") {
            return (
              <Link key={game.id} className="row p16" to={"/scan/" + game.id}>
                <div style={style.date}>{game.datum}</div>
                <div style={style.game}>
                  {game.team1.shortname} vs. {game.team2.shortname}
                </div>
              </Link>
            );
          }
        })}
      </div>
    );
  }
}

export default Gamelist;

const style = {};

style.date = {
  display: "inline-block",
  fontWeight: "bold",
  color: "#CCC",
  fontSize: "12px",
  marginRight: "12px"
};

style.game = {
  display: "inline-block",
  fontWeight: "regular",
  color: "#666",
  fontSize: "12px"
};
